import React from "react"
import Particles from "react-particles-js"
import styled from "styled-components"
import Typed from 'react-typed';


const StyledWrapper = styled.div`
  background: linear-gradient(38deg, rgba(63, 94, 251, 1) 10%, rgba(198, 70, 252, 1) 86%);
  height: 100vh;
`
const TextLayout = styled.div`
position: absolute;
display: flex;
flex-wrap: wrap;
width: 100%;
color: white;
font-weight: bold;
z-index: 10;
`
const TitleContainer = styled.div`
 width: 100%;
 display: flex;
 flex-direction: column;
 font-size: 72px;
 margin-top: 20vh;
`

const LandingPage = ({ subheading, title, children }) => (
    <StyledWrapper>
        <TextLayout>
            <TitleContainer className="landingFontResponse">
          <span style={{
              marginLeft: "20%",
              paddingBottom: "15px"
          }}>
              { title }
          </span>
                <Typed
                    style={{marginLeft: "20%"}}
                    strings={subheading}
                    typeSpeed={40}
                    loop
                />
            </TitleContainer>
        </TextLayout>
        <div  style={{
            position: "absolute",
            bottom: "5vh",
            zIndex: "10",
            marginRight: "auto",
            marginLeft: "auto",
            left: 0,
            right: 0
        }}>
            {children}
        </div>
        <Particles
            height="calc(100vh - 61px)"
            style={{
                position: "absolute",
                height: "calc(100vh - 61px)",
                zIndex: "0"
            }}
            params={{
                particles: {
                    number: {
                        value: 80,
                        density: {
                            enable: !0,
                            value_area: 800
                        }
                    },
                    color: {
                        value: '#ffffff'
                    },
                    shape: {
                        type: 'circle',
                        stroke: {
                            width: 0,
                            color: '#ffffff'
                        },
                    },
                    opacity: {
                        value: 1,
                        random: !1,
                        anim: {
                            enable: !1,
                            speed: 10,
                            opacity_min: .1,
                            sync: !1
                        }
                    },
                    size: {
                        value: 3,
                        random: !0,
                        anim: {
                            enable: !1,
                            speed: 10,
                            size_min: .1,
                            sync: !1
                        }
                    },
                    line_linked: {
                        enable: !0,
                        distance: 150,
                        color: '#ffffff',
                        opacity: .4,
                        width: 1
                    },
                    move: {
                        enable: !0,
                        speed: 3,
                        direction: 'none',
                        random: !1,
                        straight: !1,
                        out_mode: 'out',
                        bounce: !1,
                        attract: {
                            enable: !1,
                            rotateX: 600,
                            rotateY: 1200
                        }
                    },
                },

                interactivity: {
                    events: {
                        onclick: {
                            enable: false
                        }
                    }
                }
            }}/>
    </StyledWrapper>
)

export default LandingPage;