import styled from "styled-components"


const MouseIcon = styled.div`
 background: white linear-gradient(transparent 0%, transparent 50%, #fff 50%, #fff 100%);
 position: relative;
 width: 52px;
 height: 88px;
 border-radius: 100px;
 cursor: pointer;
 background-size: 100% 200%;
 :hover {
  animation: colorSlide 5s linear infinite, nudgeMouse 5s ease-out infinite;
 };
 :before, :after {
 content: "";
 position: absolute;
 top: 0;
 right: 0;
 bottom: 0;
 left: 0;
 margin: auto;
 } 
 :before {
 width: 46px;
 height: 82px;
 background: linear-gradient(38deg, rgba(63, 94, 251, 1) 10%, rgba(198, 70, 252, 1) 86%);
 border-radius: 100px;
 }
 :after {
 background-color: #fff;
 width: 10px;
 height: 10px;
 border-radius: 100%;
 animation: trackBallSlide 5s linear infinite;
 }
 @keyframes colorSlide { 
 0% {
  background-position: 0% 100%;
  }
 20% {
  background-position: 0% 0%;
  }
 21% {
  background-color: rgba(63, 94, 251, 1);
  }
  29.99% {
  background-color: #fff;
  background-position: 0% 0%;
  }
  30% {
  background-color: rgba(198, 70, 252, 1);
  background-position: 0% 100%;
  }
  50% {
  background-position: 0% 0%;
  }
  51% {
  background-color: rgba(63, 94, 251, 1);
  }
  59% {
  background-color: #fff;
  background-position: 0% 0%;
  }
  60% {
  background-color: rgba(198, 70, 252, 1);
  background-position: 0% 100%;
  }
  80% {
  background-position: 0% 0%;
  }
  81% {
  background-color: rgba(63, 94, 251, 1);
  }
  90%, 100% {
  background-color: #fff;
  }
}
@keyframes trackBallSlide {
  0% {
  opacity: 1;
  transform: scale(1) translateY(-20px);
  }
  6% {
  opacity: 1;
  transform: scale(0.9) translateY(5px);
  }
  14% {
  opacity: 0;
  transform: scale(0.4) translateY(40px);
  }
  15%, 19% {
  opacity: 0;
  transform: scale(0.4) translateY(-20px);
  }
\t 28%, 29.99% {
\t\t opacity: 1;
\t\t transform: scale(1) translateY(-20px);
\t}
\t 30% {
\t\t opacity: 1;
\t\t transform: scale(1) translateY(-20px);
\t}
\t 36% {
\t\t opacity: 1;
\t\t transform: scale(0.9) translateY(5px);
\t}
\t 44% {
\t\t opacity: 0;
\t\t transform: scale(0.4) translateY(40px);
\t}
\t 45%, 49% {
\t\t opacity: 0;
\t\t transform: scale(0.4) translateY(-20px);
\t}
\t 58%, 59.99% {
\t\t opacity: 1;
\t\t transform: scale(1) translateY(-20px);
\t}
\t 60% {
\t\t opacity: 1;
\t\t transform: scale(1) translateY(-20px);
\t}
\t 66% {
\t\t opacity: 1;
\t\t transform: scale(0.9) translateY(5px);
\t}
\t 74% {
\t\t opacity: 0;
\t\t transform: scale(0.4) translateY(40px);
\t}
\t 75%, 79% {
\t\t opacity: 0;
\t\t transform: scale(0.4) translateY(-20px);
\t}
\t 88%, 100% {
\t\t opacity: 1;
\t\t transform: scale(1) translateY(-20px);
\t}
}
 @keyframes nudgeMouse {
\t 0% {
\t\t transform: translateY(0);
\t}
\t 20% {
\t\t transform: translateY(8px);
\t}
\t 30% {
\t\t transform: translateY(0);
\t}
\t 50% {
\t\t transform: translateY(8px);
\t}
\t 60% {
\t\t transform: translateY(0);
\t}
\t 80% {
\t\t transform: translateY(8px);
\t}
\t 90% {
\t\t transform: translateY(0);
\t}
}
 @keyframes nudgeText {
\t 0% {
\t\t transform: translateY(0);
\t}
\t 20% {
\t\t transform: translateY(2px);
\t}
\t 30% {
\t\t transform: translateY(0);
\t}
\t 50% {
\t\t transform: translateY(2px);
\t}
\t 60% {
\t\t transform: translateY(0);
\t}
\t 80% {
\t\t transform: translateY(2px);
\t}
\t 90% {
\t\t transform: translateY(0);
\t}
}
`
export default MouseIcon;


