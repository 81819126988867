import React, {useRef} from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import Features from '../components/Features'
import BlogRoll from '../components/BlogRoll'
import LandingPage from "../components/Landing";
import MouseIcon from "../components/MouseIcon";

const scrollToRef = (ref) => window.scrollTo({left: 0, top: ref.current.offsetTop, behavior: 'smooth'});


export const IndexPageTemplate = ({
  image,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
  title
}) => {
    const myRef = useRef(null);
    const executeScroll = () => scrollToRef(myRef);

    const makeArray = (a) => {
            return a.split(',');
    };
    return (
        <div>
            <LandingPage
                subheading={makeArray(subheading)}
                title={title}
            >
                <MouseIcon
                    onClick={executeScroll}
                    style={{
                        marginRight: "auto",
                        marginLeft: "auto",
                        left: 0,
                        right: 0,
                    }}
                />
            </LandingPage>
            <div
                className="full-width-image margin-top-0 jumbotron"
                style={{
                    backgroundPosition: `top left`,
                    backgroundAttachment: `fixed`,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        height: '150px',
                        lineHeight: '1',
                        justifyContent: 'space-around',
                        alignItems: 'left',
                        flexDirection: 'column',
                    }}
                >
                    <h1
                        className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
                        style={{
                            color: 'white',
                            lineHeight: '1',
                            padding: '0.25em',
                        }}
                    >
                    </h1>
                    <h3
                        className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
                        style={{
                            color: 'white',
                            lineHeight: '1',
                            padding: '0.25em',
                        }}
                    >
                    </h3>
                </div>
            </div>
            <section ref={myRef} className="section section--gradient inset">
                <div className="container">
                    <div className="section">
                        <div className="columns">
                            <div className="column is-10 is-offset-1">
                                <div className="content">
                                    <div className="content">
                                        <div className="tile">
                                            <h1 className="title">{mainpitch.title}</h1>
                                        </div>
                                        <div className="tile">
                                            <h3 className="subtitle-text">{mainpitch.description}</h3>
                                        </div>
                                    </div>
                                    <div className="columns">
                                        <div className="column is-12">
                                            <h3 className="has-text-weight-semibold is-size-2">
                                                {heading}
                                            </h3>
                                            <p>{description}</p>
                                        </div>
                                    </div>
                                    <Features gridItems={intro.blurbs}/>
                                    <div className="columns">
                                        <div className="column is-12 has-text-centered">
                                            <Link className="btn" to="/products">
                                                Dowiedz się więcej
                                            </Link>
                                        </div>
                                    </div>
                                    {/*
                                    <div className="column is-12">
                                        <h3 className="has-text-weight-semibold is-size-2">
                                            Aktualności
                                        </h3>
                                        <BlogRoll/>
                                        <div className="column is-12 has-text-centered">
                                            <Link className="btn" to="/blog">
                                                Czytaj więcej
                                            </Link>
                                        </div>
                                    </div>
                                    */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
};


IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
            title  
          }
          heading
          description
        }
      }
    }
  }
`
